import React, { useContext } from 'react';

import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
  Collapse,
  useMediaQuery
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import PersonIcon from '@mui/icons-material/Person';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CampaignIcon from '@mui/icons-material/Campaign';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import LoupeIcon from '@mui/icons-material/Loupe';
import AddchartIcon from '@mui/icons-material/Addchart';
import MmsTwoToneIcon from '@mui/icons-material/MmsTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import BeachAccessTwoToneIcon from '@mui/icons-material/BeachAccessTwoTone';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import FilterVintageTwoToneIcon from '@mui/icons-material/FilterVintageTwoTone';
import HowToVoteTwoToneIcon from '@mui/icons-material/HowToVoteTwoTone';
import LocalPharmacyTwoToneIcon from '@mui/icons-material/LocalPharmacyTwoTone';
import RedeemTwoToneIcon from '@mui/icons-material/RedeemTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import TrafficTwoToneIcon from '@mui/icons-material/TrafficTwoTone';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import ChromeReaderModeTwoToneIcon from '@mui/icons-material/ChromeReaderModeTwoTone';
import WorkspacePremiumTwoToneIcon from '@mui/icons-material/WorkspacePremiumTwoTone';
import CameraFrontTwoToneIcon from '@mui/icons-material/CameraFrontTwoTone';
import DisplaySettingsTwoToneIcon from '@mui/icons-material/DisplaySettingsTwoTone';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import FastForwardIcon from '@mui/icons-material/FastForward';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AddIcon from '@mui/icons-material/Add';

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const [jobsOpen, setJobsOpen] = React.useState(false);

  const handleJobsClick = () => {
    setJobsOpen(!jobsOpen);
  };

  const MenuWrapper = styled(Box)(
    ({ theme }) => `
    .MuiList-root {
      padding: ${theme.spacing(1)};
  
      & > .MuiList-root {
         padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
      }
    }
  
      .MuiListSubheader-root {
        text-transform: uppercase;
        font-weight: bold;
        font-size: ${theme.typography.pxToRem(12)};
        color: ${theme.colors.alpha.trueWhite[50]};
        padding: ${theme.spacing(0, 2.5)};
        line-height: 1.4;
      }
  `
  );

  const SubMenuWrapper = styled(Box)(
    ({ theme }) => `
      .MuiList-root {
  
        .MuiListItem-root {
          padding: 1px 0;
  
          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(0)};
  
            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(10)};
              font-weight: bold;
              text-transform: uppercase;
              color: ${theme.palette.primary.contrastText};
            }
          }
      
          .MuiButton-root {
            display: flex;
            color: ${theme.colors.alpha.black[70]};
            background-color: transparent;
            width: 165px;
            font-size: ${theme.typography.pxToRem(12)};
            justify-content: ${isSmallScreen ? 'flex-start' : 'center'};
            padding: ${theme.spacing(1.2, 1)};
  
            .MuiButton-startIcon,
            .MuiButton-endIcon {
              transition: ${theme.transitions.create(['color'])};
  
              .MuiSvgIcon-root {
                font-size: inherit;
                transition: none;
              }
            }
  
            .MuiButton-startIcon {
              color: ${theme.colors.alpha.black[30]};
              font-size: ${theme.typography.pxToRem(15)};
              margin-right: ${theme.spacing(1)};
            }
            
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.black[50]};
              margin-left: auto;
              opacity: .8;
              font-size: ${theme.typography.pxToRem(20)};
            }
  
            &.active,
            &:hover {
              background-color: ${alpha(theme.colors.alpha.black[100], 0.1)};
              color: ${theme.colors.alpha.black[100]};
  
              .MuiButton-startIcon,
              .MuiButton-endIcon {
                color: ${theme.colors.alpha.black[100]};
              }
            }
          }
  
          &.Mui-children {
            flex-direction: column;
  
            .MuiBadge-root {
              position: absolute;
              right: ${theme.spacing(7)};
            }
          }
  
          .MuiCollapse-root {
            width: 100%;
  
            .MuiList-root {
              padding: ${theme.spacing(1, 0)};
            }
  
            .MuiListItem-root {
              padding: 1px 0;
  
              .MuiButton-root {
                padding: ${theme.spacing(0.8, 3)};
  
                .MuiBadge-root {
                  right: ${theme.spacing(3.2)};
                }
  
                &:before {
                  content: ' ';
                  background: ${theme.colors.alpha.trueWhite[100]};
                  opacity: 0;
                  transition: ${theme.transitions.create([
                    'transform',
                    'opacity'
                  ])};
                  width: 6px;
                  height: 6px;
                  transform: scale(0);
                  transform-origin: center;
                  border-radius: 20px;
                  margin-right: ${theme.spacing(1.8)};
                }
  
                &.active,
                &:hover {
  
                  &:before {
                    transform: scale(1);
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
  `
  );
  // const NestedSubMenuWrapper = styled(Box)(
  //   ({ theme }) => `
  //     .MuiList-root {
  //       padding-left: ${theme.spacing(3)};

  //       .MuiListItem-root {
  //         padding: ${theme.spacing(0.2, 0)};

  //         .MuiButton-root {
  //           padding: ${theme.spacing(0.8, 1)};

  //           &:hover,
  //           &.active {
  //             background-color: ${alpha(theme.colors.alpha.black[100], 0.1)};
  //           }
  //         }
  //       }
  //     }
  //   `
  // );

  const NestedSubMenuWrapper = styled(Box)(
    ({ theme }) => `
  
      .MuiList-root {
        background-color: ${alpha(theme.colors.alpha.white[100], 1)};
    
        position: ${isSmallScreen ? 'relative' : 'absolute'};
        top: ${isSmallScreen ? 'auto' : '60px'};
        left: ${isSmallScreen ? 'auto' : '40%'};
        width: ${
          isSmallScreen ? '100%' : 'auto'
        }; /* Full width for small screens, auto for large screens */
      }
  
      .MuiListItem-root {
        padding: ${theme.spacing(0.2, 0)};
    
        .MuiButton-root {
          padding: ${theme.spacing(0.8, 1)}; 
        
  
          &:hover,
          &.active {
            background-color: ${alpha(theme.colors.alpha.black[100], 0.1)};
          }
        }
      }
    `
  );

  const CollapseWrapper = styled(Box)(
    ({ theme }) => `
      .MuiCollapse-root {
        width: 100%;
      }
    `
  );

  const isSmallScreen = useMediaQuery('(max-width:1280px)');
  return (
    <>
      <MenuWrapper>
        <SubMenuWrapper>
          <List
            sx={{
              display: 'flex',
              flexDirection: isSmallScreen ? 'column' : 'row' // Change direction to column on small screens
            }}
            component="div"
          >
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={() => {
                  closeSidebar();
                  setJobsOpen(false);
                }}
                to="/dashboards/home"
                startIcon={<DashboardIcon />}
              >
                Calendar
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={() => {
                  closeSidebar();
                  setJobsOpen(false);
                }}
                to="/dashboards/contractors"
                startIcon={<PersonIcon />}
              >
                Contractors
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={() => {
                  closeSidebar();
                  setJobsOpen(false);
                }}
                to="/dashboards/quote"
                startIcon={<LoupeIcon />}
              >
                Quotes & Invoices
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={() => {
                  closeSidebar();
                  setJobsOpen(false);
                }}
                to="/dashboards/jobTracker"
                startIcon={<BusinessCenterIcon />}
              >
                Job Tracker
              </Button>
            </ListItem>
            {/* <Collapse in={jobsOpen} timeout="auto" unmountOnExit>
              <NestedSubMenuWrapper>
                <List component="div">
                <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={() => {
                        closeSidebar();
                        setJobsOpen(false) 
                      }}
                      to="/dashboards/createJob"
                      startIcon={<AddIcon />}
                    >
                      Create Job
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={() => {
                        closeSidebar();
                        setJobsOpen(false) 
                      }}
                      to="/dashboards/availablejobs"
                      startIcon={<BusinessCenterIcon />}
                    >
                     Upcoming Jobs
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={() => {
                        closeSidebar();
                        setJobsOpen(false) 
                      }}
                      to="/dashboards/activeJobs"
                      startIcon={<FastForwardIcon />}
                    >
                      Active Jobs
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={() => {
                        closeSidebar();
                        setJobsOpen(false) 
                      }}
                      to="/dashboards/completeJobs"
                      startIcon={<VerifiedUserIcon />}
                    >
                      Completed Jobs
                    </Button>
                  </ListItem>
                 
                </List>
              </NestedSubMenuWrapper>
            </Collapse> */}
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={() => {
                  closeSidebar();
                  setJobsOpen(false);
                }}
                to="/dashboards/financials"
                startIcon={<CurrencyExchangeIcon />}
              >
                Finanical Overview
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={() => {
                  closeSidebar();
                  setJobsOpen(false);
                }}
                to="/dashboards/messenger"
                startIcon={<ChatRoundedIcon />}
              >
                Chat
              </Button>
            </ListItem>
            {/* <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={() => {
                  closeSidebar();
                  setJobsOpen(false) 
                }}
                to="/dashboards/positions"
                startIcon={<AddchartIcon/>}
              >
                Positions
              </Button>
            </ListItem> */}
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={() => {
                  closeSidebar();
                  setJobsOpen(false);
                }}
                to="/dashboards/announcement"
                startIcon={<CampaignIcon />}
              >
                Announcement
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;

{
  /* </List> */
}
{
  /* <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Management
            </ListSubheader>
          }
        > */
}
{
  /* <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/transactions"
                  startIcon={<TableChartTwoToneIcon />}
                >
                  Transactions List
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper> */
}
{
  /* </List> */
}
{
  /* <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Accounts
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/profile/details"
                  startIcon={<AccountCircleTwoToneIcon />}
                >
                  User Profile
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/profile/settings"
                  startIcon={<DisplaySettingsTwoToneIcon />}
                >
                  Account Settings
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Components
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/buttons"
                  startIcon={<BallotTwoToneIcon />}
                >
                  Buttons
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/modals"
                  startIcon={<BeachAccessTwoToneIcon />}
                >
                  Modals
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/accordions"
                  startIcon={<EmojiEventsTwoToneIcon />}
                >
                  Accordions
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/tabs"
                  startIcon={<FilterVintageTwoToneIcon />}
                >
                  Tabs
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/badges"
                  startIcon={<HowToVoteTwoToneIcon />}
                >
                  Badges
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/tooltips"
                  startIcon={<LocalPharmacyTwoToneIcon />}
                >
                  Tooltips
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/avatars"
                  startIcon={<RedeemTwoToneIcon />}
                >
                  Avatars
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/cards"
                  startIcon={<SettingsTwoToneIcon />}
                >
                  Cards
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/components/forms"
                  startIcon={<TrafficTwoToneIcon />}
                >
                  Forms
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Extra Pages
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/status/404"
                  startIcon={<CheckBoxTwoToneIcon />}
                >
                  Error 404
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/status/500"
                  startIcon={<CameraFrontTwoToneIcon />}
                >
                  Error 500
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/status/coming-soon"
                  startIcon={<ChromeReaderModeTwoToneIcon />}
                >
                  Coming Soon
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/status/maintenance"
                  startIcon={<WorkspacePremiumTwoToneIcon />}
                >
                  Maintenance
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List> */
}
