import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Formik, Form, Field, useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// components
import Swal from 'sweetalert2';
import { postJwtLogin } from '../../helper/backend_helper';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required')
  });

  // const handleLogin = async (values) => {
  //   try {
  //     setLoading(true);
  //     // const response = await postJwtLogin(values);
  //     // localStorage.setItem('moodlift', JSON.stringify(response.data.data));
  //     localStorage.setItem('moodlift');
  //     setLoading(false);
  //     navigate('/dashboard', { replace: true });
  //     window.location.reload();
  //   } catch (error) {
  //     setLoading(false);
  //     if (error?.response?.status >= 400 || error?.response?.status < 500) {
  //       if (typeof value === 'string') {
  //         Swal.fire({
  //           position: 'center',
  //           icon: 'error',
  //           title: error.response.data.message,
  //           showConfirmButton: false,
  //           timer: 1500,
  //         });
  //       }

  //       Swal.fire({
  //         position: 'center',
  //         icon: 'error',
  //         title: error.response.data.message[0],
  //         showConfirmButton: false,
  //         timer: 1500,
  //       });
  //     }
  //   }
  // };

  const handleLogin = async (values) => {
    try {
      setLoading(true);
      const headers = {
        headers: {
          'content-type': 'application/json'
        }
      };

      const response = await postJwtLogin(values, headers);

      if (response.data.data.user.Role.name !== 'ADMIN') {
        setLoading(false);

        return toast.error(
          'You have entered an invalid email address or password',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
          }
        );
      }

      localStorage.setItem('PLS', JSON.stringify(response.data.data));
      navigate('/dashboards/home', { replace: true });
      window.location.reload();
    } catch (err) {
      console.log(err);
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: err?.response?.data?.message,
        showConfirmButton: false,
        timer: 1500
      });
      setLoading(false);
    }
  };

  // setLoading(true);
  // localStorage.setItem('moodlift', JSON.stringify(values));
  // setLoading(false);
  //     navigate('/dashboard', { replace: true });
  //          window.location.reload();

  const formik = useFormik({
    initialValues: {
      password: '',
      email: ''
    },
    validationSchema,
    onSubmit: (values) => handleLogin(values)
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <TextField
          size="small"
          label="Email address"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && !!formik.errors.email}
          helperText={formik.touched.email && formik.errors.email}
        />

        <TextField
          size="small"
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            )
          }}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && !!formik.errors.password}
          helperText={formik.touched.password && formik.errors.password}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      />

      <LoadingButton
        fullWidth
        size="medium"
        type="submit"
        variant="contained"
        onClick={() => handleLogin}
        loading={loading}
        sx={{
          backgroundColor: '#000000',
          color: '#E8BF21',
          '&:hover': {
            backgroundColor: '#000000',
            color: '#E8BF21'
          }
        }}
      >
        Login
      </LoadingButton>
    </form>
  );
}
