import { useState } from 'react';
import {
  Box,
  Typography,
  Hidden,
  Container,
  Button,
  Grid
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
import LoadingButton from '@mui/lab/LoadingButton';
import PLS from '../../assets/images/PLS.png';
import icon from '../../assets/images/Logo.svg';
import logoAnimation from '../../assets/images/Logo animation.gif';
import { styled } from '@mui/material/styles';
import LoginForm from './LoginForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const GridWrapper = styled(Grid)(
  ({ theme }) => `
    background: ${theme.colors.gradients.black1};
`
);

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const TypographyPrimary = styled(Typography)(
  ({ theme }) => `
      color: ${theme.colors.alpha.white[100]};
`
);

const TypographySecondary = styled(Typography)(
  ({ theme }) => `
      color: ${theme.colors.alpha.white[70]};
`
);
const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

function Login() {
  const [pending, setPending] = useState(false);
  function handleClick() {
    setPending(true);
  }

  return (
    <>
      <Helmet>
        <title>Prestige Labour Solutions</title>
      </Helmet>
      <MainContent>
        <Grid
          container
          sx={{ height: '100%' }}
          alignItems="stretch"
          spacing={0}
        >
          <ToastContainer />
          <Grid
            xs={12}
            md={6}
            alignItems="center"
            display="flex"
            justifyContent="center"
            item
          >
            {/* <Container maxWidth="lg"> */}
            <Box
              textAlign="center"
              style={{
                borderRadius: 20,
                background: 'black',
                padding: 5,
                width: 500,
                height: 500,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <img
                alt="500"
                width={400}
                height={400}
                src="https://prestige.codecoytechnologies.live/Icon.png"
                style={{ objectFit: 'contain' }}
              />
            </Box>
            {/* </Container> */}
          </Grid>
          <Container maxWidth="sm">
            <StyledContent>
              <img
                style={{ width: '25%', height: '25%', alignSelf: 'center' }}
                src="https://prestige.codecoytechnologies.live/PLS.png"
                alt="login"
              />
              <Typography variant="h5" gutterBottom mb={3} mt={6}>
                Sign In to Prestige Labour Solution LLC
              </Typography>
              <LoginForm />
            </StyledContent>
          </Container>
        </Grid>
      </MainContent>
    </>
  );
}

export default Login;
