import {
  Avatar,
  Tooltip,
  IconButton,
  Box,
  Button,
  styled,
  InputBase,
  useTheme,
  CircularProgress,
  Icon,
  Divider,
  Card
} from '@mui/material';
import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import { config } from 'src/config';
import { useRef, useState } from 'react';
import { sendMessage, uploadFile } from 'src/helper/backend_helper';
import { CancelOutlined } from '@mui/icons-material';
// import { socket } from './ChatContent';

const MessageInputWrapper = styled(InputBase)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(18)};
    padding: ${theme.spacing(1)};
    width: 100%;
`
);

const Input = styled('input')({
  display: 'none'
});

function BottomBarContent({
  chat,
  messages,
  setMessages,

  scrollToBottom,
  socket
}) {
  const theme = useTheme();
  const ref: any = useRef();

  const user = JSON.parse(localStorage.getItem('PLS'))?.user;

  const [media, setMedia] = useState(null);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('text');
  const [showMediaPopup, setShowMediaPopup] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleSendMessage = async () => {
    // event.preventDefault();

    if (!message && !media) {
      return;
    }
    try {
      // const receiver = chat.participants.find((e) => e.userId !== user.id);

      const data = {
        chatId: chat.id,
        senderId: user?.id,
        content: message,
        type: messageType
      };

      console.log(data);
      const headersJson = {
        headers: {
          'content-type': 'application/json'
        }
      };
      setLoading(true);
      if (messageType === 'text') {
        const response = await sendMessage(data, headersJson);
        // setMessages([...messages, response.data.data]);
        await socket.emit('message', response.data.data);

        setMessage('');
      }

      setLoading(false);

      if (messageType !== 'text') {
        const headers = {
          headers: {
            'content-type': 'multipart/form-data'
          }
        };

        const formData = new FormData();
        formData.append('file', media);
        const responseFile = await uploadFile(formData, headers);
        console.log(responseFile);
        data.content = responseFile.data.data.filename;
        const response = await sendMessage(data, headersJson);
        // setAllMessages([...allMessages, response.data.data]);
        await socket.emit('message', response.data.data);

        setMedia(null);
        setShowMediaPopup(false);
        setMessageType('text');
      }
      scrollToBottom();
      // event.target.reset();
      // setSendMessageLoading(false);
    } catch (error) {
      // setSendMessageLoading(false);
      // toast.error(error.message, {
      //   position: 'top-right',
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: 'light'
      // });
    }
  };

  function handleSelectMedia(file) {
    // console.log(file);
    if (file) {
      setMedia(file);
      const type = file.type.split('/')[0];

      setMessageType(type);
      // if (type === 'audio') {
      //   setMessageType('audio');
      // }
      if (type === 'application') {
        setMessageType('file');
      }
      return setShowMediaPopup(true);
    }
    setMedia(null);
    setShowMediaPopup(false);
    setMessageType('text');
  }

  function handleMediaCancel() {
    setMedia(null);
    setShowMediaPopup(false);
    setMessageType('text');
  }

  return (
    <Box
      sx={
        {
          // p: 2
        }
      }
    >
      {messageType === 'image' && media && (
        <Card sx={{ position: 'relative', display: 'flex', m: 2, width: 150 }}>
          <img
            src={URL.createObjectURL(media)}
            alt="message_image"
            width={150}
          />

          <Tooltip arrow placement="top" title="Cancel">
            <label htmlFor="messenger-upload-file">
              <IconButton
                color="primary"
                component="span"
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0

                  // '&:hover': {
                  //   backgroundColor: 'gray'
                  // }
                }}
                onClick={() => handleMediaCancel()}
              >
                <CancelOutlined fontSize="small" />
              </IconButton>
            </label>
          </Tooltip>
        </Card>
      )}

      {messageType !== 'image' && media && (
        <Card
          sx={{
            position: 'relative',
            display: 'flex',
            m: 1,
            p: 3,
            maxWidth: 300
          }}
        >
          <small>
            {media.name.length > 30
              ? `${media.name.slice(0, 20)}...${media.name.slice(
                  media.name.length - 10
                )}`
              : media.name}
          </small>

          <Tooltip arrow placement="top" title="Cancel">
            <label htmlFor="messenger-upload-file">
              <IconButton
                size="small"
                color="primary"
                component="span"
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0

                  // '&:hover': {
                  //   backgroundColor: 'gray'
                  // }
                }}
                onClick={() => handleMediaCancel()}
              >
                <CancelOutlined fontSize="small" />
              </IconButton>
            </label>
          </Tooltip>
        </Card>
      )}
      <Divider />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          background: theme.colors.alpha.white[50],
          p: 2
        }}
      >
        <Box flexGrow={1} display="flex" alignItems="center">
          <Avatar
            sx={{ display: { xs: 'none', sm: 'flex' }, mr: 1 }}
            alt={user.name}
            src={config.FILe_URL + user.profileImage}
          />
          <MessageInputWrapper
            autoFocus
            placeholder="Write your message here..."
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            disabled={!!media || loading}
          />
        </Box>
        <Box>
          <input
            ref={ref}
            type="file"
            onChange={(e) => handleSelectMedia(e.target.files[0])}
            hidden
            value=""
          />

          <Tooltip arrow placement="top" title="Attach a file">
            <label
              htmlFor="messenger-upload-file"
              onClick={() => ref.current.click()}
            >
              <IconButton sx={{ mx: 1 }} color="primary" component="span">
                <AttachFileTwoToneIcon fontSize="small" />
              </IconButton>
            </label>
          </Tooltip>
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            <Button
              startIcon={<SendTwoToneIcon />}
              variant="contained"
              onClick={() => handleSendMessage()}
            >
              Send
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default BottomBarContent;
