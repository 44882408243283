import {
  Box,
  Avatar,
  Typography,
  Card,
  styled,
  Divider,
  Button
} from '@mui/material';

import {
  formatDistance,
  format,
  subDays,
  subHours,
  subMinutes
} from 'date-fns';
import ScheduleTwoToneIcon from '@mui/icons-material/ScheduleTwoTone';
import { useEffect, useRef, useState } from 'react';
import { getMessages } from 'src/helper/backend_helper';
import moment from 'moment';
import { config } from 'src/config';
import { Download } from '@mui/icons-material';

const DividerWrapper = styled(Divider)(
  ({ theme }) => `
      .MuiDivider-wrapper {
        border-radius: ${theme.general.borderRadiusSm};
        text-transform: none;
        background: ${theme.palette.background.default};
        font-size: ${theme.typography.pxToRem(13)};
        color: ${theme.colors.alpha.black[50]};
      }
`
);

const CardWrapperPrimary = styled(Card)(
  ({ theme }) => `
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      padding: ${theme.spacing(2)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-right-radius: ${theme.general.borderRadius};
      max-width: 380px;
      display: inline-flex;
`
);

const CardWrapperSecondary = styled(Card)(
  ({ theme }) => `
      background: ${theme.colors.alpha.black[10]};
      color: ${theme.colors.alpha.black[100]};
      padding: ${theme.spacing(2)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-left-radius: ${theme.general.borderRadius};
      max-width: 380px;
      display: inline-flex;
`
);

function handleOpenImage(url) {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
}

function ChatContent({ chat, messages, setMessages, scrollToBottom, socket }) {
  const user = JSON.parse(localStorage.getItem('PLS'))?.user;

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalMessages, setTotalMessages] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const getData = async () => {
      const response = await getMessages(chat.id, { page: 1, pageSize: 10 });
      const sorted = response.data.data.sort((a, b) =>
        a.createdAt > b.createdAt ? 1 : -1
      );

      setMessages(sorted);
      setPage(1);
      setPageSize(10);
      setTotalMessages(response.data.meta.totalCount);
    };
    getData();
    scrollToBottom();
  }, [chat]);

  useEffect(() => {
    const getData = async () => {
      if (page > 1) {
        const response = await getMessages(chat.id, { page, pageSize });
        const sorted = response.data.data.sort((a, b) =>
          a.createdAt > b.createdAt ? 1 : -1
        );

        setMessages([...sorted, ...messages]);
        setTotalMessages(response.data.meta.totalCount);
      }
    };
    getData();
  }, [page]);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 500);
    console.log('Socket Connected', socket.connected);

    socket.emit('join', { chatId: chat.id });
  }, [socket, chat]);

  useEffect(() => {
    socket.on('message', (data) => {
      if (chat?.id === data?.chatId) {
        setMessages((msgs) => [...msgs, data]);
        scrollToBottom();
      }
    });

    return () => {
      socket.off('message');
    };
  }, [socket, chat]);

  return (
    <Box p={3}>
      {/* <DividerWrapper>
        {format(subDays(new Date(), 3), 'MMMM dd yyyy')}
      </DividerWrapper> */}

      {totalMessages > pageSize && messages.length < totalMessages && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={() => setPage(page + 1)}
            // onKeyDown={() => handleNextChat()}
            role="button"
            tabIndex={0}
            style={{ cursor: 'pointer' }}
          >
            View Previous Messages
          </Button>
        </div>
      )}

      {messages.map((item) => {
        return item.senderId !== user.id ? (
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-start"
            py={3}
            key={item.id}
          >
            <Avatar
              variant="rounded"
              sx={{
                width: 50,
                height: 50
              }}
              alt="Zain Baptista"
              src={config.FILe_URL + item.sender.profileImage}
            />
            <Box
              display="flex"
              alignItems="flex-start"
              flexDirection="column"
              justifyContent="flex-start"
              ml={2}
            >
              {item.type === 'text' && (
                <CardWrapperSecondary>{item.content}</CardWrapperSecondary>
              )}
              {item.type === 'image' && (
                <CardWrapperSecondary
                  onClick={() =>
                    handleOpenImage(config.FILe_URL + item.content)
                  }
                  sx={{ cursor: 'pointer' }}
                >
                  <img
                    src={config.FILe_URL + item.content}
                    width={250}
                    height={250}
                  />
                </CardWrapperSecondary>
              )}

              {item.type === 'audio' && (
                <CardWrapperSecondary
                  onClick={() =>
                    handleOpenImage(config.FILe_URL + item.content)
                  }
                  sx={{ cursor: 'pointer' }}
                >
                  {item.content} <Download />
                </CardWrapperSecondary>
              )}
              {item.type === 'file' && (
                <CardWrapperSecondary
                  onClick={() =>
                    handleOpenImage(config.FILe_URL + item.content)
                  }
                  sx={{ cursor: 'pointer' }}
                >
                  {item.content} <Download />
                </CardWrapperSecondary>
              )}
              <Typography
                variant="caption"
                sx={{
                  pt: 1,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <ScheduleTwoToneIcon
                  sx={{
                    mr: 0.5,
                    fontSize: 20
                  }}
                  // fontSize={10}
                />
                {moment(new Date(item.createdAt)).format('DD-MM-YYYY HH:MM A')}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-end"
            py={3}
          >
            <Box
              display="flex"
              alignItems="flex-end"
              flexDirection="column"
              justifyContent="flex-end"
              mr={2}
            >
              {item.type === 'text' && (
                <CardWrapperPrimary>{item.content}</CardWrapperPrimary>
              )}
              {item.type === 'image' && (
                <CardWrapperPrimary
                  onClick={() =>
                    handleOpenImage(config.FILe_URL + item.content)
                  }
                  sx={{ cursor: 'pointer' }}
                >
                  <img
                    src={config.FILe_URL + item.content}
                    width={250}
                    height={250}
                  />
                </CardWrapperPrimary>
              )}

              {item.type === 'audio' && (
                <CardWrapperPrimary
                  onClick={() =>
                    handleOpenImage(config.FILe_URL + item.content)
                  }
                  sx={{ cursor: 'pointer' }}
                >
                  {item.content} <Download />
                </CardWrapperPrimary>
              )}
              {item.type === 'file' && (
                <CardWrapperPrimary
                  onClick={() =>
                    handleOpenImage(config.FILe_URL + item.content)
                  }
                  sx={{ cursor: 'pointer' }}
                >
                  {item.content} <Download />
                </CardWrapperPrimary>
              )}
              <Typography
                variant="caption"
                sx={{
                  pt: 1,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <ScheduleTwoToneIcon
                  sx={{
                    mr: 0.5,
                    fontSize: 20
                  }}
                  // fontSize="small"
                />

                {moment(new Date(item.createdAt)).format('DD-MM-YYYY HH:MM A')}
              </Typography>
            </Box>
            <Avatar
              variant="rounded"
              sx={{
                width: 50,
                height: 50
              }}
              alt={user.name}
              src={config.FILe_URL + user.profileImage}
            />
          </Box>
        );
      })}

      {/* <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-end"
        py={3}
      >
        <Box
          display="flex"
          alignItems="flex-end"
          flexDirection="column"
          justifyContent="flex-end"
          mr={2}
        >
          <CardWrapperPrimary>
            Yes, I'll email them right now. I'll let you know once the remaining
            invoices are done.
          </CardWrapperPrimary>
          <Typography
            variant="subtitle1"
            sx={{
              pt: 1,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <ScheduleTwoToneIcon
              sx={{
                mr: 0.5
              }}
              fontSize="small"
            />
            {formatDistance(subHours(new Date(), 125), new Date(), {
              addSuffix: true
            })}
          </Typography>
        </Box>
        <Avatar
          variant="rounded"
          sx={{
            width: 50,
            height: 50
          }}
          alt={user.name}
          src={user.avatar}
        />
      </Box>
      <DividerWrapper>
        {format(subDays(new Date(), 5), 'MMMM dd yyyy')}
      </DividerWrapper>

      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-end"
        py={3}
      >
        <Box
          display="flex"
          alignItems="flex-end"
          flexDirection="column"
          justifyContent="flex-end"
          mr={2}
        >
          <CardWrapperPrimary>Hey! Are you there?</CardWrapperPrimary>
          <CardWrapperPrimary
            sx={{
              mt: 2
            }}
          >
            Heeeelloooo????
          </CardWrapperPrimary>
          <Typography
            variant="subtitle1"
            sx={{
              pt: 1,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <ScheduleTwoToneIcon
              sx={{
                mr: 0.5
              }}
              fontSize="small"
            />
            {formatDistance(subHours(new Date(), 60), new Date(), {
              addSuffix: true
            })}
          </Typography>
        </Box>
        <Avatar
          variant="rounded"
          sx={{
            width: 50,
            height: 50
          }}
          alt={user.name}
          src={user.avatar}
        />
      </Box>
      <DividerWrapper>Today</DividerWrapper>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-start"
        py={3}
      >
        <Avatar
          variant="rounded"
          sx={{
            width: 50,
            height: 50
          }}
          alt="Zain Baptista"
          src="/static/images/avatars/2.jpg"
        />
        <Box
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          justifyContent="flex-start"
          ml={2}
        >
          <CardWrapperSecondary>Hey there!</CardWrapperSecondary>
          <CardWrapperSecondary
            sx={{
              mt: 1
            }}
          >
            How are you? Is it ok if I call you?
          </CardWrapperSecondary>
          <Typography
            variant="subtitle1"
            sx={{
              pt: 1,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <ScheduleTwoToneIcon
              sx={{
                mr: 0.5
              }}
              fontSize="small"
            />
            {formatDistance(subMinutes(new Date(), 6), new Date(), {
              addSuffix: true
            })}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-end"
        py={3}
      >
        <Box
          display="flex"
          alignItems="flex-end"
          flexDirection="column"
          justifyContent="flex-end"
          mr={2}
        >
          <CardWrapperPrimary>
            Hello, I just got my Amazon order shipped and I’m very happy about
            that.
          </CardWrapperPrimary>
          <CardWrapperPrimary
            sx={{
              mt: 1
            }}
          >
            Can you confirm?
          </CardWrapperPrimary>
          <Typography
            variant="subtitle1"
            sx={{
              pt: 1,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <ScheduleTwoToneIcon
              sx={{
                mr: 0.5
              }}
              fontSize="small"
            />
            {formatDistance(subMinutes(new Date(), 8), new Date(), {
              addSuffix: true
            })}
          </Typography>
        </Box>
        <Avatar
          variant="rounded"
          sx={{
            width: 50,
            height: 50
          }}
          alt={user.name}
          src={user.avatar}
        />
      </Box> */}
    </Box>
  );
}

export default ChatContent;
