// export const config = {
//   API_URL: 'http://localhost:5015/api/v1',
//   FILe_URL: 'http://localhost:5015/',
//   PDF_URL: 'https://admin.referease.co.uk/berkshire/public/pdf/'
// };

export const config = {
  API_URL: 'https://prestigeapi.codecoytechnologies.live/api/v1',
  FILe_URL: 'https://prestigeapi.codecoytechnologies.live/',
  PDF_URL: 'https://admin.referease.co.uk/berkshire/public/pdf/'
};
